import React from "react"
import { navigate } from "gatsby"
import HeroBanner from "../components/HeroBanner"
import Container from "../components/Container"
import SEO from "../components/SEO"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    return (
      <div>
        <HeroBanner
          title="Contact with David"
          subTitle="I will reply to your enquiry within 1 working day and I am happy to have exploratory discussions."
        />
        <SEO title="Contact with David" />
        <Container>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            className="mb-6"
            action="/success"
            netlify-honeypot="phone-number"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <p className="is-hidden">
              <label>
                Don’t fill this out if you're human:{" "}
                <input name="phone-number" />
              </label>
            </p>
            <div className="columns">
              <div className="field column">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    className="input"
                    name="name"
                    type="text"
                    placeholder="eg. Luke Skywalker"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field column">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    className="input"
                    name="email"
                    type="email"
                    placeholder="eg. luke@starwars.com"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">Your Message</label>
              <div className="control">
                <textarea
                  className="textarea"
                  name="message"
                  placeholder="Tell me about your project"
                  onChange={this.handleChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button className="button is-link">Submit</button>
              </div>
            </div>
          </form>
        </Container>
      </div>
    )
  }
}
